/**
 * Custom Image & Background component
 */

import * as React from "react"
import ReactPlayer from "react-player"

export class Image extends React.Component {
  render() {
    const w = this.props.width
    const h = this.props.height
    const alt = this.props.alt
    const classes = this.props.className
    const id = this.props.id
    // Exit if no source provided
    if (!this.props.src && !this.props.placeholder) {
      throw new Error("Error in Image component. Both source and placeholder missing")
    }
    // If both placeholder and source go to lazyload mode
    if (this.props.placeholder && this.props.src) {
      //console.log("Placeholder exists. Running lazyload mode")
      return (
        <img
          id={id}
          className={`${classes} lazyload`}
          src={this.props.placeholder}
          srcSet={this.props.srcSet}
          data-srcset={this.props.srcSet}
          data-src={this.props.src}
          width={w}
          height={h}
          alt={alt}
          style={this.props.style}
        />
      )
    }
    // If just placeholder or source go to simple mode
    //console.log("Placeholder is missing. Running default mode")
    const image = this.props.src ? this.props.src : this.props.placeholder
    return (
      <img
        id={id}
        className={`${classes} lazyload`}
        src={image}
        srcSet={this.props.srcSet}
        data-src={image}
        data-srcset={this.props.srcSet}
        width={w}
        height={h}
        alt={alt}
        style={this.props.style}
      />
    )
  }

  //const
  //src, placeholder, width, height, className, id
  /*
    static defaultProps = {
        return (

        )
    }
    */
}

export class Background extends React.Component {
  render() {
    //console.log(this.props)
    const srcSet = this.props.srcSet ? this.props.srcSet : null
    const overlay = this.props.overlay ? this.props.overlay : "transparent"
    const propsStyle = this.props.style

    // If both placeholder and src are missing exit
    if (!this.props.src && !this.props.placeholder) {
      return <div
        style={{ ...propsStyle }}>
        <div>
          {this.props.children}
        </div>
      </div>
    }
    // If we have placeholder and src then enable lazyload mode plus adaptive (fluid)
    if (this.props.src && this.props.placeholder) {
      let addedStyle = {
        backgroundImage: `url(${this.props.placeholder})`,
      }
      return (
        <div
          className={`vinbgx lazyload ${this.props.className}`}
          data-bg={this.props.src}
          data-sizes="auto"
          data-bgset={srcSet}
          style={{ ...propsStyle, ...addedStyle }}
        >
          <div
            style={{
              background: overlay,
            }}
          >
            {this.props.children}
          </div>
        </div>
      )
    }
    // If we have either src or placeholder
    const backgroundImage = this.props.src ? this.props.src : this.props.placeholder
    let addedStyle = {
      backgroundImage: `url(${backgroundImage})`,
    }
    return (
      <div
        className={`vinbgx lazyload ${this.props.className}`}
        data-bg={backgroundImage}
        data-sizes="auto"
        data-bgset={srcSet}
        style={{ ...propsStyle, ...addedStyle }}
      >
        <div
          style={{
            background: overlay,
          }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

export class VideoBackground extends React.Component {
  render() {
    const url = this.props.url ? this.props.url : null

    return (
      <div className="background-video-container uk-flex uk-flex-center">
        <ReactPlayer
          url={url}
          playing
          playsinline
          loop
          controls={false}
          muted={true}
          width="100%"
          height="100vh"
        />
      </div>
    )
  }
}
